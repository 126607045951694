import React from "react";

const SpinnerIcon = () => (
  <>
    <svg
      version="1.1"
      id="Layer_1"
      xmlns="http://www.w3.org/2000/svg"
      xmlnsXlink="http://www.w3.org/1999/xlink"
      x="0px"
      y="0px"
      viewBox="0 0 64 64"
      xmlSpace="preserve"
    >
      <g>
        <path
          d="M31.9,63.7C14.4,63.7,0,49.3,0,31.9S14.4,0.3,31.9,0.3c4.7,0,9.4,1.1,13.9,3c1.4,0.6,1.9,2.2,1.4,3.6s-2.2,1.9-3.6,1.4
		C39.9,6.6,36,5.8,32.1,5.8c-14.4,0-26,11.6-26,26s11.6,26,26,26c10,0,19.1-5.5,23.5-14.7c1.7-3.6,2.5-7.5,2.5-11.4
		c0-2.8-0.3-5.3-1.1-7.8c-0.6-1.4,0.3-3,1.9-3.6c1.4-0.6,3,0.3,3.6,1.9c0.8,3,1.4,6.4,1.4,9.4c0,4.7-1.1,9.4-3,13.9
		C55.1,56.5,43.8,63.7,31.9,63.7z"
        />
      </g>
    </svg>
  </>
);

export default SpinnerIcon;
